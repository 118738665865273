import uiFlagsActionTypes from '../actions/uiFlagsActions';
import projectListActionsTypes from '../actions/projectListActions'
import MobileDetect from 'mobile-detect'

export const defaultNewModelSource = {
    file: null
}

export const initialState = {
    checkedProjects: [],
    activeTabIndex: 0,
    createNewProjectDlgVisible: false,
    uploadNewVersionDlgVisible: false,
    editProjectDataDlgVisible: false,
    newModelSource: defaultNewModelSource,
    newProjectCreationError: null,
    processingPaneVisible: false,
    processingCurrentMessage: null,
    projectEditionState: {
        name: "",
        adress: "",
        dataSheetId: "",
        searchKey: "",
        id: null,
        autoUpdate: true
    },
    deleteProjectsDlgVisible: false,
    projectsLoaded: false,
    isMobile: !!(new MobileDetect(window.navigator.userAgent).mobile()),
    visualizationControlVisibleForMobile: false
}

export const isMobile = function (state) {
    return state.isMobile;
}

export const isVisualizationControlVisibleForMobileDevice = function (state) {
    return state.visualizationControlVisibleForMobile;
}

export const checkedProjects = function (state) {
    return state.checkedProjects;
}

export const areProjectsLoaded = function (state) {
    return state.projectsLoaded;
}


export const activeTabIndex = function (state) {
    return state.activeTabIndex;
};

export const isNewProjectDialogVisisble = function (state) {
    return state.createNewProjectDlgVisible;
}

export const isNewVersionDialogVisible = function (state) {
    return state.uploadNewVersionDlgVisible;
}

export const isProjectDataEditorDialogVisible = function (state) {
    return state.editProjectDataDlgVisible;
}

export const getCurrentProjecEditionState = function (state) {
    return state.projectEditionState
}

export const getNewModelSource = function (state) {
    return state.newModelSource;
}

export const getCreateNewProjectError = function (state) {
    return state.newProjectCreationError
}

export const isProcessingPaneVisible = function (state) {
    return state.processingPaneVisible;
}

export const getProcessingMessage = function (state) {
    return state.processingCurrentMessage;
}

export const isProjectsDeleteDialogVisible = function (state) {
    return state.deleteProjectsDlgVisible;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case uiFlagsActionTypes.SET_PROJECT_CHECKED:
            {
                const idx = state.checkedProjects.indexOf(action.projectId);
                let checkedProjects = [];

                if (action.checked) {
                    checkedProjects = state.checkedProjects.slice();
                    if (idx === -1) {
                        checkedProjects = checkedProjects.concat([action.projectId]);
                    }
                } else {
                    // remove
                    checkedProjects = state.checkedProjects.filter(id => id !== action.projectId);
                }

                return { ...state, checkedProjects }
            }
        case uiFlagsActionTypes.SET_CHECKED_PROJECTS:
            return { ...state, checkedProjects: action.projects };

        case uiFlagsActionTypes.CLEAR_CHECKED_PROJECTS:
            return { ...state, checkedProjects: [] };


        case uiFlagsActionTypes.UPDATE_ACTIVE_TAB_INDEX:
            return { ...state, activeTabIndex: action.index};

        case uiFlagsActionTypes.SHOW_CREATE_NEW_PROJECT: {
            return { ...state, newModelSource: defaultNewModelSource, createNewProjectDlgVisible: action.visible }
        }

        case uiFlagsActionTypes.SHOW_UPLOAD_NEW_VERSION: {
            return { ...state, newModelSource: defaultNewModelSource, uploadNewVersionDlgVisible: action.visible }
        }

        case uiFlagsActionTypes.SHOW_EDIT_PROJECT_DATA: {
            return { ...state, editProjectDataDlgVisible: action.visible }
        }

        case uiFlagsActionTypes.SET_PROJECT_EDITION_STATE: {
            return { ...state, projectEditionState: action.state }
        }

        case uiFlagsActionTypes.MODEL_SOURCE_FILE_SELECTED: {
            const newState = { ...state };
            const modelSource = { ...newState.newModelSource, file: action.file }

            return { ...newState, newModelSource: modelSource, newProjectCreationError: null }
        }

        case uiFlagsActionTypes.SHOW_CREATE_NEW_PROJECT_ERROR: {
            return { ...state, newProjectCreationError: action.message }
        }

        case projectListActionsTypes.PROCESS_PROJECT: {
            return { ...state, processingPaneVisible: action.visible, processingCurrentMessage: null }
        }

        case projectListActionsTypes.PROJECT_LIST_UPDATED: {
            return { ...state, checkedProjects: [], projectsLoaded: true }
        }

        case projectListActionsTypes.PROCESS_PROJECT_REPORT_PROGRESS: {
            return { ...state, processingCurrentMessage: action.message }
        }

        case (uiFlagsActionTypes.SHOW_DELETE_PROJECTS_DIALOG) : {
            return { ...state, deleteProjectsDlgVisible: action.visible }
        }

        case uiFlagsActionTypes.MOBILE_SHOW_VISUALIZATION_CONTROL: {
            return { ...state, visualizationControlVisibleForMobile: action.visible }
        }

        default:
            return state;
    }
}