import {actionTypes} from '../actions/loginActions'

export const initialState = {
    login: "",
    password: "",
    loginResult: {
       isSuccess: true,
       message: ""
    }
}

export const canSignIn = function(state) {
    return state.login !== "" && state.password !== "";
}

export const getUserName = function(state) {
    return state.login;
}

export const getPassword = function(state) {
    return state.password;
}

export const getLoginResults = function(state) {
   return state.loginResult;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type) {
        case actionTypes.EDIT_LOGIN_FORM_MESSAGE: 
            return { ...state, login: action.login, loginResult: {isSuccess: true, message: ""}}

        case actionTypes.EDIT_PASSWORD_FORM_MESSAGE: 
            return { ...state, password: action.password, loginResult: {isSuccess: true, message: ""}}
        
        case actionTypes.SHOW_LOGIN_FAILED:
            return { ...state, loginFailedShowing: true, loginResult: {isSuccess: false, message: action.message} };
        
        default:
            return state;
    }
}