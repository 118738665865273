import { getUserData } from '../reducers/mainReducer';
// import { showLoginFailed, showLoginForm, updateActiveTabIndex } from './uiFlagsActions'
import { storeToken, cleanToken, loadProfile } from './profileActions'
import { fetchProjects } from "./projectListActions";
import repo from '../Repository';

export const actionTypes = {
    EDIT_LOGIN_FORM_MESSAGE: "EDIT_LOGIN_FORM_MESSAGE",
    EDIT_PASSWORD_FORM_MESSAGE: "EDIT_PASSWORD_FORM_MESSAGE",
    SHOW_LOGIN_FAILED: "SHOW_LOGIN_FAILED"
}


export const editLogin = (newLogin) => {
    return {
        type: actionTypes.EDIT_LOGIN_FORM_MESSAGE,
        login: newLogin
    }
}

export const editPassword = (newPassword) => {
    return {
        type: actionTypes.EDIT_PASSWORD_FORM_MESSAGE,
        password: newPassword
    }
}

export const showLoginFailed = (message) => {
    return {
        type: actionTypes.SHOW_LOGIN_FAILED,
        message
    };
};

export const tryLogin = () => async (dispatch, getState) => {
    var userData = getUserData(getState());

    let loginAttemptResult = null;

    try {
        loginAttemptResult = await repo.login(userData);
    } catch (e) {
        dispatch(showLoginFailed("Application error occured. Try later, please"));

        return;
    }

    if (!loginAttemptResult.isSuccess) {
        dispatch(showLoginFailed(loginAttemptResult.message));

        return;
    }

    const token = loginAttemptResult.item;
    
    repo.setAccessToken(token);
    storeToken(loginAttemptResult.item);

    dispatch(loadProfile());

    dispatch(fetchProjects());
}

export const logout = ()  => {
    cleanToken();

    window.location.reload();
}