export default class EventsEmitter {

    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    constructor () {
      this._events = {}
    }
  
    /////////////////////////////////////////////////////////
    // Supports multiple events in array
    //
    /////////////////////////////////////////////////////////
    on (events, fct) {
  
      const eventsArray = !Array.isArray(events)
        ? [events]
        : events
  
      eventsArray.forEach(event => {
        this._events[event] = this._events[event]	|| []
        this._events[event].push(fct)
      })
  
      return this
    }
  
    /////////////////////////////////////////////////////////
    // Supports multiple events space-separated
    //
    /////////////////////////////////////////////////////////
    off (events, fct) {
  
      if (events === undefined) {
        this._events = {}
        return
      }
  
      const eventsArray = !Array.isArray(events)
        ? [events]
        : events
  
      eventsArray.forEach(event => {
  
        if (event in this._events === false)
          return;
  
        if (fct) {
          const entries = this._events[event].entries()
          for (const [idx, handler] of entries) {
            if (fct === handler) {
              this._events[event].splice(idx, 1) 
            }
          }
        } else {
          this._events[event] = []
        }
      })
  
      return this
    }
  
    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    emit (event /* , args... */) {
  
      if (this._events[event] === undefined)
        return
  
      const handlers = this._events[event].slice()
  
      for (let handler of handlers) {
        const result = handler.apply(this,
          Array.prototype.slice.call(arguments, 1))
        if (!(result instanceof Promise) && result) {
          return result
        }
      }
    }
  
    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    async emitAcc (event, args) {
     
      if (this._events[event] === undefined)
        return
  
      const handlers = this._events[event].slice()
  
      let resultAcc = undefined
  
      for (let handler of handlers) {
  
        const result = handler({
          ...args,
          ...resultAcc
        })
  
        resultAcc = (result instanceof Promise)
          ? await result || resultAcc
          : resultAcc
      }
  
      return resultAcc
    }
  
    /////////////////////////////////////////////////////////
    //
    //
    /////////////////////////////////////////////////////////
    guid (format='xxxxxxxxxxxx') {
  
      var d = new Date().getTime();
  
      var guid = format.replace(
        /[xy]/g,
        function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          // eslint-disable-next-line no-mixed-operators
          return (c === 'x' ? r : (r & 0x7 | 0x8)).toString(16);
        });
  
      return guid;
    }
  }