import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getActiveProject, getConstructionStages, getConstructionItems, getConstructionDates, 
    isConstructionLoading, areProjectsLoaded, isMobile, isVisualizationControlVisibleForMobileDevice } from '../reducers/mainReducer';
import ContentNotReady from "./contentNotReady";
import VisualizationControl from "./visualizationControl";
import Script from 'react-load-script';
import './forgeView.css';
import repo from '../Repository';
import EventsEmitter from './eventsEmitter'
import Loader from "./loader";

const viewerVersion = '7.*';

export const viewerCss = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/style.min.css`;
export const viewerJs = `https://developer.api.autodesk.com/modelderivative/v2/viewers/${viewerVersion}/viewer3D.min.js`;

let Autodesk = null;

export class ForgeView extends Component {
    constructor(props) {
        super(props);

        this.viewerDiv = React.createRef();
        this.viewer = null;
        this.eventSink = new EventsEmitter()
    }

    async getToken() {
        if (this.token && this.token.expirationDate > new Date()) {
            return this.token;
        }

        const token = await repo.getToken();

        this.token = {
            token: token.token,
            expirationDate: new Date(token.expiresIn)
        }

        return this.token;
    }

    handleScriptLoad = async () => {
        if (!this.props.projectUrn)
            return;

        const token = await this.getToken();

        const options = {
            accessToken: token.token,
            env: 'AutodeskProduction2',
            api: 'streamingV2'
        }

        Autodesk = window.Autodesk;

        const container = this.viewerDiv.current;
        if (this.props.isMobile)
            this.viewer = new Autodesk.Viewing.Viewer3D(container);
        else
            this.viewer = new Autodesk.Viewing.GuiViewer3D(container);
        this.viewer.setTheme("light-theme");

        Autodesk.Viewing.Initializer(options, this.handleViewerInit.bind(this));
    }

    handleViewerInit() {
        const errorCode = this.viewer.start();
        if (errorCode)
            return;

        Autodesk.Viewing.Document.load("urn:" + this.props.projectUrn, this.onDocumentLoadSuccess.bind(this), () => { })
    }

    componentDidUpdate(prevProps) {
        if (Autodesk && (this.props.projectUrn !== prevProps.projectUrn)) {
            const projectUrn = this.props.projectUrn || "";
            if (projectUrn !== "") {
                Autodesk.Viewing.Document.load(
                    "urn:" + projectUrn, this.onDocumentLoadSuccess.bind(this), () => { }
                );

                this.eventSink.emit("constructions.clear")
            }
            else if (this.viewer && this.viewer.model) {
                this.viewer.unloadModel(this.viewer.model)
            }
        }
    }

    async onDocumentLoadSuccess(viewerDocument) {
        const getGeometry = function () {
            var root = viewerDocument.getRoot();
            var defaultGeometry = root.getDefaultGeometry();

            if (defaultGeometry.is3D())
                return defaultGeometry;

            var threeDModels = root.get3DModelNodes();

            if (threeDModels.length > 0)
                return threeDModels[0];

            return defaultGeometry;
        }

        const viewables = getGeometry();

        await this.viewer.loadDocumentNode(viewerDocument, viewables);

        try {
            await import('./forgeViewer4D.js');

            await this.viewer.loadExtension("AI.ConstructionVisualizationExtension", {
                eventSink: this.eventSink
            });

            if (!this.props.isLoading)
                this.eventSink.emit("constructions.refresh", {
                    stages: this.props.stages,
                    items: this.props.items,
                    dates: this.props.dates
                })
        } catch (ex) {
            console.log(ex)
        }
    }

    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.finish();
            this.viewer = null;
            Autodesk.Viewing.shutdown();
        }
    }

    render() {
        this.eventSink.emit("constructions.refresh", {
            stages: this.props.stages,
            items: this.props.items,
            dates: this.props.dates
        })
        const projectUrn = this.props.projectUrn || "";

        const containerClass = this.props.isMobile ? "modelContainer-mobile" : "modelContainer fullheight"

        return (
            <div className={containerClass}>
                <div className="viewer" id="ForgeViewer">
                    <div ref={this.viewerDiv}></div>
                    {this.props.projectsLoaded && projectUrn === "" && <ContentNotReady label="Модель еще не готова для просмотра" />}
                    <link rel="stylesheet" type="text/css" href={viewerCss} />
                    {projectUrn !== "" && <Script url={viewerJs} onLoad={this.handleScriptLoad.bind(this)} />}
                    <Loader show={!this.props.projectsLoaded}/>
                </div>
                {this.props.isVisualizationControlVisibleForMobileDevice && <VisualizationControl />}
            </div>)
    }
}

export default connect(function (store) {
    return {
        projectUrn: getActiveProject(store)?.derivativeUrn,
        stages: getConstructionStages(store),
        items: getConstructionItems(store),
        dates: getConstructionDates(store),
        isLoading: isConstructionLoading(store),
        projectsLoaded: areProjectsLoaded(store),
        isMobile: isMobile(store),
        isVisualizationControlVisibleForMobileDevice: isVisualizationControlVisibleForMobileDevice(store)
    };
})(ForgeView);