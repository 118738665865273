import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import Label from '@hig/label';
import Input from '@hig/input';
import Button from '@hig/button';
import IconButton from '@hig/icon-button';
import Typography from "@hig/typography";
import Spacer from '@hig/spacer';
import { Folder24 } from '@hig/icons';
import merge from "lodash.merge";

import { isNewVersionDialogVisible, getNewModelSource, getCreateNewProjectError } from "../reducers/mainReducer";
import { showUploadNewVersionDialog, selectModelSourceFile, showCreateNewProjectError } from "../actions/uiFlagsActions";
import { uploadNewProjectVersion } from "../actions/projectListActions";

import './projectForm.css';

export class CreateNewModelVersionForm extends Component {
    constructor(props) {
        super(props);
        this.onPackageFileChange = this.onPackageFileChange.bind(this);
    }

    onPackageFileChange(data) {
        if (data.target.files.length > 0) {
            this.props.selectModelSourceFile(data.target.files[0])
        }
    }

    render() {
        const modalStyles = styles =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "230px"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        const hasUpload = this.props.projectSourceData.file?.name.endsWith('.dwg') === true;
        return (<React.Fragment>
            <Modal
                open={this.props.isVisible}
                title="Новая версия модели"
                onCloseClick={() => this.props.showUploadNewVersionDialog(false)}
                stylesheet={modalStyles}>
                <div id="project-data">
                    <div className="fileBrowseContainer">
                        <div className="stretch">
                            <Label
                                variant="top"
                                disabled={false} >
                                DWG файл модели:
                                </Label>
                            <Input
                                variant="box"
                                value={this.props.projectSourceData.file?.name || ''}
                                disabled={true}
                            />
                        </div>
                        <div className="browseButton">
                            <label htmlFor="modelFileInput">
                                <IconButton
                                    icon={<Folder24 />}
                                    title="Выберите DWG-файл модели"
                                    onClick={() => { document.getElementById("modelFileInput").click(); }}
                                />
                            </label>
                            <input id="modelFileInput"
                                type="file"
                                accept=".dwg"
                                onChange={(e) => {
                                    this.onPackageFileChange(e);
                                }}
                            />
                        </div>
                    </div>
                    {this.props.errorMessage !== null && <Typography children={this.props.errorMessage} className="creation-failed-message" />}
                    <Spacer spacing="l" />
                    <div className="buttonsContainer">
                        <Button
                            size="standard"
                            title="Загрузить"
                            type="primary"
                            onClick={() => this.props.uploadNewProjectVersion()}
                            disabled={!hasUpload} />
                        <div style={{ width: '14px' }} />
                        <Button
                            id="cancel_button"
                            size="standard"
                            title="Отмена"
                            type="secondary"
                            onClick={() => { this.props.showUploadNewVersionDialog(false); }} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>)
    }
}

export default connect(function (store) {
    return {
        isVisible: isNewVersionDialogVisible(store),
        projectSourceData: getNewModelSource(store),
        errorMessage: getCreateNewProjectError(store)
    }
}, { showUploadNewVersionDialog, selectModelSourceFile, showCreateNewProjectError, uploadNewProjectVersion })(CreateNewModelVersionForm)