import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import Label from '@hig/label';
import Input from '@hig/input';
import Button from '@hig/button';
import CheckBox from "@hig/checkbox";
import Typography from "@hig/typography";
import Spacer from '@hig/spacer';
import merge from "lodash.merge";

import { isProjectDataEditorDialogVisible, getCreateNewProjectError, getCurrentProjecEditionState, getProject, checkedProjects } from "../reducers/mainReducer";
import { showEditProjectDialog, showCreateNewProjectError, setProjectEditionState } from "../actions/uiFlagsActions";
import { editProject } from "../actions/projectListActions";

import './projectForm.css';

export class EditProjectDataForm extends Component {

    onChanged(newEditionState) {
        this.props.showCreateNewProjectError(null);
        this.props.setProjectEditionState(newEditionState);
    }

    onProjectKeyChanged(data) {
        const newEditionState = { ...this.props.editionState, searchKey: data };
        
        this.onChanged(newEditionState);
    }

    onProjectNameChanged(data) {
        const newEditionState = { ...this.props.editionState, name: data };

        this.onChanged(newEditionState);
    }

    onProjectDataSheetChanged(data) {
        const newEditionState = { ...this.props.editionState, dataSheetId: data };

        this.onChanged(newEditionState);
    }

    onProjectAdressChanged(data) {
        const newEditionState = { ...this.props.editionState, adress: data };

        this.onChanged(newEditionState);
    }

    onProjectAutoUpdateChanged(data) {
        const newEditionState = { ...this.props.editionState, autoUpdate: data };
        
        this.onChanged(newEditionState);
    }

    componentDidUpdate() {
        if (!this.props.activeProject?.id)
            return;

        if (this.props.activeProject.id !== this.props.editionState.id) {
            this.props.setProjectEditionState(this.props.activeProject);
        }
    }

    render() {
        const modalStyles = styles =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "530px"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        return (<React.Fragment>
            <Modal
                open={this.props.isVisible}
                title="Изменить проект"
                onCloseClick={() => this.props.showEditProjectDialog(false)}
                stylesheet={modalStyles}>
                <div id="project-data">
                    <div className="stretch">
                        <Label variant="top">Ключ (будет в строке запроса браузера):</Label>
                        <Input variant="box" onChange={data => this.onProjectKeyChanged(data.target.value)} value={this.props.editionState.searchKey}/>
                    </div>

                    <div className="stretch">
                        <Label variant="top">ID таблицы Google:</Label>
                        <Input variant="box" onChange={data => this.onProjectDataSheetChanged(data.target.value)} value={this.props.editionState.dataSheetId}/>
                    </div>

                    <div className="stretch">
                        <Label variant="top">Наименование:</Label>
                        <Input variant="box" onChange={data => this.onProjectNameChanged(data.target.value)} value={this.props.editionState.name} />
                    </div>

                    <div className="stretch">
                        <Label variant="top">Адрес:</Label>
                        <Input variant="box" onChange={data => this.onProjectAdressChanged(data.target.value)} tagName="textarea" value={this.props.editionState.adress} />
                    </div>

                    <div className="stretch checkboxfield">
                        <CheckBox checked={this.props.editionState.autoUpdate} onChange={data => this.onProjectAutoUpdateChanged(data)}/>
                        <div className="checkboxtext">Обновлять автоматически</div>
                    </div>

                    {this.props.errorMessage !== null && <Typography children={this.props.errorMessage} className="creation-failed-message"/>}

                    <Spacer spacing="l" />

                    <div className="buttonsContainer">
                        <Button
                            size="standard"
                            title="Изменить"
                            type="primary"
                            onClick={() => this.props.editProject(this.props.activeProject.id, this.props.editionState)}
                            />
                        <div style={{ width: '14px' }} />
                        <Button
                            id="cancel_button"
                            size="standard"
                            title="Отмена"
                            type="secondary"
                            onClick={() => { this.props.showEditProjectDialog(false); }} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>);
    }
}

export default connect(function (store) {
    const checked = checkedProjects(store);

    let activeProject = null;

    if (checked && checked.length > 0)
        activeProject = getProject(checked[0], store);

    return {
        isVisible: isProjectDataEditorDialogVisible(store),
        errorMessage: getCreateNewProjectError(store),
        editionState: getCurrentProjecEditionState(store),
        activeProject: activeProject
    }
}, { showEditProjectDialog, showCreateNewProjectError, setProjectEditionState, editProject })(EditProjectDataForm)