import React, { Component } from 'react';
import { connect } from 'react-redux';
import Tabs, { Tab } from "@hig/tabs";
import ProjectList from "./projectList";
import VisualizationControl from "./visualizationControl";
import ForgeView from "./forgeView";
import { activeTabIndex, getActiveProject } from '../reducers/mainReducer';
import { updateActiveTabIndex } from '../actions/uiFlagsActions';

import './tabs.css';

export class TabsContainer extends Component {
    onTabChange(index) {
        this.props.updateActiveTabIndex(index);
    }

    render() {
        const idx = this.props.activeTabIndex;
        return (<div className="tabsContainer">
            <Tabs
                className="fullheight"
                align="center"
                showTabDivider={false}
                onTabChange={(index) => { this.onTabChange(index); }}
                onTabClose={() => { }}
                activeTabIndex={idx}>
                <Tab label="Проекты">
                    <div className="tabContent fullheight" id="projects-list">
                        <ProjectList />
                    </div>
                </Tab>

                <Tab label="Модель" disabled={!this.props.hasActiveProject}>
                    <div id="model" className='tabContent fullheight'>
                        <div className='inARow fullheight'>
                            <VisualizationControl />
                            <ForgeView />
                        </div>
                    </div>
                </Tab>
            </Tabs>
        </div>)
    }
}

export default connect(function (store) {
    return {
        hasActiveProject: getActiveProject(store)?.id !== undefined,
        activeTabIndex: activeTabIndex(store),
    };
}, { updateActiveTabIndex })(TabsContainer);