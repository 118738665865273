import React, { Component } from 'react';
import { connect } from 'react-redux';
import './loader.css'

export class Loader extends Component {
    static defaultProps = {
        color: '#fcc755'
    }

    render() {
        const loaderStyle = {
            color: this.props.color
        }

        if (!this.props.show)
            return (null);

        return (
            <div className="loader" style={loaderStyle} />
        )
    }
}

export default connect()(Loader)