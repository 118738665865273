import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'react-base-table/styles.css';
import ProjectsTable from "./projectsTable";
import { getActiveProject, checkedProjects, isLoggedIn } from '../reducers/mainReducer';
import { updateActiveProject } from "../actions/projectListActions";
import { updateConstructionsFromGoogleSheets } from "../actions/constructionActions";
import { updateActiveTabIndex, showCreateNewProjectDialog, showUploadNewVersionDialog, showEditProjectDialog, showDeleteProjectsDialog } from "../actions/uiFlagsActions";
import IconButton from '@hig/icon-button';
import { Item24 , Trash24, Edit24, Upload24, Import24 } from '@hig/icons';
import CreateNewProjectForm from "./createNewProjectForm";
import CreateNewModelVersionForm from "./createNewModelVersionForm";
import EditProjectDataForm from "./editProjectDataForm";
import ProjectProcessingPane from "./projectProcessingPane";
import DeleteProjectsDialog from "./deleteProjectsDialog";
import "./projectList.css";

export class ProjectList extends Component {
    onProjectClick(projectId) {
        this.props.updateActiveProject(projectId);
        this.props.updateActiveTabIndex(1); // switch to MODEL tab
    }
    render() {
        const checkedProjects = this.props.checkedProjects && this.props.checkedProjects.length > 0;
        const deleteButtonVisible = this.props.isLoggedIn && checkedProjects;

        const deleteContainerClass = deleteButtonVisible ? "" : "hidden";
        const spacerClass = (this.props.isLoggedIn && deleteButtonVisible) ? "verticalSpacer" : "verticalSpacer hidden";

        return (<div className="fullheight">
            <div className="actionButtonContainer">
                <div>
                    <IconButton 
                        icon={<Item24 />}
                        title="Новый проект"
                        onClick={() => this.props.showCreateNewProjectDialog(true)}
                        />
                </div>
                <div className={spacerClass}></div>
                    <div className={deleteContainerClass}>
                        <IconButton 
                            icon={<Edit24 />}
                            title="Редактировать проект"
                            onClick={() => this.props.showEditProjectDialog(true)}
                            />
                    </div>
                    <div className={deleteContainerClass}>
                        <IconButton 
                            icon={<Upload24 />}
                            title="Загрузить новую версию модели"
                            onClick={() => this.props.showUploadNewVersionDialog(true)}
                            />
                    </div>
                    <div className={deleteContainerClass}>
                        <IconButton
                            icon={<Trash24 />}
                            title="Удалить проект(ы)"
                            onClick={() => this.props.showDeleteProjectsDialog(true)} 
                            />
                    </div>

                    <div className={deleteContainerClass}>
                        <IconButton 
                            icon={<Import24 />}
                            title="Обновить данные из таблиц google"
                            onClick={() => this.props.updateConstructionsFromGoogleSheets()}/>
                    </div>
            </div>
            <ProjectsTable
                onProjectClick={(id) => {this.onProjectClick(id)}}
                selectable={this.props.isLoggedIn} />
            <CreateNewProjectForm />
            <CreateNewModelVersionForm />
            <EditProjectDataForm />
            <DeleteProjectsDialog />
            <ProjectProcessingPane />
        </div>)
    }
}

export default connect(function (store) {
    return {
        isLoggedIn: isLoggedIn(store) === "logged",
        activeProject: getActiveProject(store),
        checkedProjects: checkedProjects(store)
    }
}, { updateActiveProject, updateActiveTabIndex, showCreateNewProjectDialog, showUploadNewVersionDialog, 
    showEditProjectDialog, showDeleteProjectsDialog, updateConstructionsFromGoogleSheets })(ProjectList);