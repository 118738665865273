import costructionActionTypes from "../actions/constructionActions";

export const initialState = {
    constructions: {}
}

export const isConstructionLoading = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    return !sheetState || sheetState.state === "loading";
}

export const getConstructionUpdatedAt = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    return sheetState?.updatedAt;
}

export const getConstructionItems = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    if (!sheetState)
        return [];

    return sheetState.items;
}

export const getConstructionStages = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    if (!sheetState)
        return [];

    return sheetState.stages;
}

export const getConstructionDates = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    if (!sheetState)
        return {
            minDate: today(),
            maxDate: today(),
            currentMinDate: today(),
            currentMaxDate: today()
        }

    return {
        minDate: sheetState.minDate,
        maxDate: sheetState.maxDate,
        currentMinDate: sheetState.currentMinDate,
        currentMaxDate: sheetState.currentMaxDate
    }
}

export const areAllStagesChecked = function (sheetId, state) {
    const sheetState = state?.constructions && state.constructions[sheetId];

    if (!sheetState)
        return true;

    return sheetState.allChecked;
}

export const adjustToMonday = function (dt) {
    if (dt.getDay() === 0)
        return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() - 6);

    const delta = 1 - dt.getDay();

    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + delta);
}

export const adjustToSunday = function (dt) {
    if (dt.getDay() === 0)
        return dt;

    const delta = 7 - dt.getDay();

    return new Date(dt.getFullYear(), dt.getMonth(), dt.getDate() + delta);
}

export const today = function() {
    return new Date(new Date().setHours(0,0,0,0))
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case costructionActionTypes.PROJECT_CONSTRUCTION_LIST_FETCH_STARTED: {
            const newState = { ...state };

            newState.constructions[action.sheetId] = {
                stages: [],
                items: [],
                state: "loading",
                updatedAt: null,
                minDate: today(),
                maxDate: today(),
                currentMinDate: today(),
                currentMaxDate: today(),
                allChecked: true
            };

            return newState;
        }

        case costructionActionTypes.PROJECT_CONSTRUCTION_LIST_UPDATED: {
            const newState = { ...state };

            let minDate = adjustToMonday(today());
            let maxDate = adjustToSunday(today());

            const selectMin = function (acc, elem) { return elem < acc ? elem : acc; };

            const selectMax = function (acc, elem) { return elem > acc ? elem : acc; };

            for (let i = 0; i < action.constructions.items.length; i++) {
                const item = action.constructions.items[i];

                const date = new Date(item.stages[item.stages.length - 1].date);

                minDate = selectMin(date, minDate);
                maxDate = selectMax(date, maxDate);
            }

            newState.constructions[action.sheetId] = {
                stages: action.constructions.stages.map(x => {
                    return { ...x, checked: true }
                }),
                items: action.constructions.items,
                state: "loaded",
                updatedAt: action.constructions.updatedAt,
                minDate: minDate,
                maxDate: maxDate,
                currentMinDate: minDate,
                currentMaxDate: maxDate,
                allChecked: true
            };

            return newState;
        }

        case costructionActionTypes.PROJECT_STAGES_CHECKED_CHANGED: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.stages = construction.stages.map(x => {
                if (x.name !== action.stageName)
                    return x;

                x.checked = action.isChecked

                return x;
            });

            construction.allChecked = construction.stages.map(x => x.checked).reduce((acc, elem) => acc && elem, true);

            return newState;
        }

        case costructionActionTypes.PROJECT_STAGES_ALL_CHECKED_CHANGED : {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.stages = construction.stages.map(x => {
                return { ...x, checked: action.isChecked };
            })

            construction.allChecked = action.isChecked;

            return newState;
        }

        case costructionActionTypes.PROJECT_MIN_DATE_CHANGED: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.currentMinDate = action.minDate;

            return newState;
        }

        case costructionActionTypes.PROJECT_MAX_DATE_CHANGED: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.currentMaxDate = action.maxDate;

            return newState;
        }

        case costructionActionTypes.PROJECT_RESET_DATES: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.currentMinDate = construction.minDate;
            construction.currentMaxDate = construction.maxDate;

            return newState;
        }

        case costructionActionTypes.PROJECT_SET_CURRENT_WEEK: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.currentMinDate = adjustToMonday(today());
            construction.currentMaxDate = adjustToSunday(today());

            return newState;
        }

        case costructionActionTypes.PROJECT_SET_TODAY: {
            const newState = { ...state };

            const construction = newState.constructions[action.sheetId];

            if (!construction)
                return state;

            construction.currentMinDate = today();
            construction.currentMaxDate = today();

            return newState;
        }

        default:
            return state;
    }
}