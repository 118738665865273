import React, { Component } from 'react';
import Label from '@hig/label';
import { connect } from 'react-redux';
import { setStageChecked, setAllStagesChecked } from "../actions/constructionActions";
import { getConstructionStages, getActiveProject, areAllStagesChecked } from "../reducers/mainReducer";
import Checkbox from '@hig/checkbox';
import "./visualizationControl.css"

export class ConstructionStages extends Component {
    constructor(props) {
        super(props);

        this.onStageCheckChange = this.onStageCheckChange.bind(this);
        this.onAllStagesCheckChange = this.onAllStagesCheckChange.bind(this);
    }

    onStageCheckChange(data, stageName) {
        this.props.setStageChecked(this.props.sheetId, stageName, data);
        this.forceUpdate();
    }

    onAllStagesCheckChange(isChecked) {
        this.props.setAllStagesChecked(this.props.sheetId, isChecked);
    }

    render() {
        return (<div>
            <Label variant="top">Стадии</Label>
            <div className="parameter checkbox" >
                <div style={{ "display": "flex" }}>
                    <div><Checkbox checked={this.props.allStagesChecked} onChange={data => this.onAllStagesCheckChange(data)}/></div>
                    <div className="parameter checkboxtext">Выделить все/снять выделение</div>
                </div>
            </div>
            {this.props.stages
                .map((x, i) => {
                    const color = "rgb(" + (255 * x.color.red) + "," + (255 * x.color.green) + "," + (255 * x.color.blue) + ")";
                    return (<div className="parameter checkbox" key={i}>
                        <div style={{ "display": "flex" }}>
                            <div><Checkbox checked={x.checked} onChange={data => this.onStageCheckChange(data, x.name)} /></div>
                            <div className="parameter checkboxtext">
                                <span className="stage-color-box" style={{ backgroundColor: color }}></span>
                                {x.name}
                            </div>
                        </div>
                    </div>);
                })}
        </div>);
    }
}

export default connect(function (store) {
    return {
        stages: getConstructionStages(store),
        sheetId: getActiveProject(store)?.dataSheetId,
        allStagesChecked: areAllStagesChecked(store)
    }
}, { setStageChecked, setAllStagesChecked })(ConstructionStages)