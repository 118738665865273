import React, { Component } from 'react';
import { connect } from 'react-redux';
import Label from '@hig/label';
import Calendar from 'react-calendar';
import Button from '@hig/button';
import { setConstructionsMinDate, setConstructionsMaxDate, resetConstructionsDates, setCurrentWeekConstructionDates, setToday  } from "../actions/constructionActions";
import { getConstructionDates, getActiveProject } from "../reducers/mainReducer";
import 'react-calendar/dist/Calendar.css';
import "./constructionTimeline.css";


export class ConstructionTimeline extends Component {

    render() {
        return (<div>
            <div className="parameter-buttonsContainer">
                <Button 
                    style={{width: '125px'}}
                    type="primary" 
                    size="standard"
                    title= "Сегодня"
                    width="grow"
                    onClick={() => this.props.setToday(this.props.sheetId)}/>

                <div style={{width: '14px'}}/>

                <Button 
                    style={{width: '125px'}}
                    type="primary" 
                    size="standard"
                    title= "Неделя"
                    width="grow"
                    onClick={() => this.props.setCurrentWeekConstructionDates(this.props.sheetId)}/>

                <div style={{width: '14px'}}/>

                <Button 
                    style={{width: '125px'}}
                    type="secondary" 
                    size="standard"
                    title= "Сброс"
                    width="grow"
                    onClick={() => this.props.resetConstructionsDates(this.props.sheetId)}/>
            </div>
            <Label variant="top">От:</Label>
            <div>
                <Calendar minDate={this.props.dates.minDate} maxDate={this.props.dates.maxDate} value={this.props.dates.currentMinDate}
                    onChange={date => this.props.setConstructionsMinDate(this.props.sheetId, date)}/>
            </div>
            <Label variant="top">До:</Label>
            <div>
                <Calendar minDate={this.props.dates.minDate} maxDate={this.props.dates.maxDate} value={this.props.dates.currentMaxDate}
                    onChange={date => this.props.setConstructionsMaxDate(this.props.sheetId, date)}/>
            </div>
        </div>);
    }
}

export default connect(function (store) {
    return {
        dates: getConstructionDates(store),
        sheetId: getActiveProject(store)?.dataSheetId
    }
}, { setConstructionsMinDate, setConstructionsMaxDate, resetConstructionsDates, setCurrentWeekConstructionDates, setToday })(ConstructionTimeline)