import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isConstructionLoading, getConstructionItems, getConstructionUpdatedAt  } from "../reducers/mainReducer";
import Loader from "./loader";
import ConstructionStages from "./constructionStages";
import ConstructionTimeline from "./constructionTimeline";
import ContentNotReady from "./contentNotReady";
import Typography from "@hig/typography";
import './visualizationControl.css';


export class VisualizationControl extends Component {
    render() {
        const updated = this.props.updatedAt ? new Date(this.props.updatedAt) : null;
        return (
            <div className="parametersContainer">
                <div className="pencilContainer"></div>
                <Loader show={this.props.isLoading}/>
                <div className="parameters">
                    {!this.props.isLoading && this.props.updatedAt && <ConstructionStages />}
                    {!this.props.isLoading && this.props.updatedAt && <ConstructionTimeline />}
                    {!(this.props.isLoading || this.props.updatedAt) && <ContentNotReady label="Данные еще не были загружены"/>}
                    {!this.props.isLoading && updated && 
                        <Typography>Обновлено: {updated.toLocaleDateString()} {updated.toLocaleTimeString()}</Typography>}
                </div>
            </div>
        );
    }
}

export default connect(function (store) {
    return {
        isLoading: isConstructionLoading(store),
        items: getConstructionItems(store),
        updatedAt: getConstructionUpdatedAt(store)
    }
}, {})(VisualizationControl)