import repo from '../Repository';
import { addError, addLog } from './notificationActions';
import { fetchProjects, fetchCurrentProject } from "./projectListActions";

const actionTypes = {
    PROFILE_LOADED: 'PROFILE_LOADED'
};

export default actionTypes;

const storageField = "reforged.constructions.portaluser.token.v2"

export const detectToken = () => async(dispatch) => {
    const locationPath = window.location.pathname;

    if (locationPath !== "/" && locationPath !== ""){
        dispatch(fetchCurrentProject(locationPath));

        dispatch(updateProfile({}, "anonymous-gallery"));

        return;
    }

    const savedToken = localStorage[storageField];

    if (savedToken){
        repo.setAccessToken(savedToken);

        const isTokenValid = await repo.validateAccessToken();
        
        if (!isTokenValid)
            repo.forgetAccessToken();
        else {
            dispatch(fetchProjects());
        }
    }
    else {
        repo.forgetAccessToken();
    }

   dispatch(loadProfile());
}

export const storeToken = (token) => {
    localStorage[storageField] = token;
}

export const cleanToken = (token) => {
    delete localStorage[storageField];
}

export const loadProfile = () => async (dispatch) => {
    dispatch(addLog('Load profile invoked'));
    try {
        const profile = await repo.loadProfile();
        dispatch(addLog('Load profile received'));
        const isLoggedIn = repo.hasAccessToken();
        dispatch(updateProfile(profile, isLoggedIn ? "logged" : "not-logged"));
    } catch (e) {
        console.log("TODO process error")
        console.log(e);
        dispatch(addError('Failed to get profile. (' + e + ')'));
    }
};

export const updateProfile = (profile, isLoggedIn) => {
    return {
        type: actionTypes.PROFILE_LOADED,
        profile,
        isLoggedIn
    };
};