import axios from 'axios';

const AuthorizationHeader = 'Authorization';

class Repository {
    async loadProjects() {
        const response = await axios.get("/projects");

        return response.data.items;
    }

    async loadProject(locationPath) {
        const response = await axios.get("/projects" + locationPath);

        return response.data.items;
    }

    async getToken() {
        const response = await axios.get("/forge/token")

        return response.data.item;
    }

    async loadConstructions(sheetid) {
        const response = await axios.get("/constructions/" + sheetid);

        return response.data.item;
    }

    async updateConstructions(projectId) {
        await axios.post("/constructions/" + projectId);
    }

    /** Load user's profile */
    async loadProfile() {
        const response = await axios.get("/login/profile");
        return response.data.item;
    }

    async validateAccessToken() {
        const response = await axios.get("/login/validate");

        return response.data.item;
    }

    async createStorageForUpload() {
        const response = await axios.post("/forge/create-storage");

        if (!response.data.isSuccess) {
            const error = new Error(response.data.message);
            error.response = response;
            throw error;
        }

        return response.data.item;
    }

    async upload(model, storage) {
        await axios({
            url: storage.signedUrl,
            method: "put",
            data: model.file
        })
    }

    async uploadModel(model) {
        const storage = await this.createStorageForUpload();

        await this.upload(model, storage);

        return storage;
    }

    async createNewProject(project, model, connectionId) {
        const formData = new FormData();

        formData.append("objectKey", model.objectKey);
        formData.append("name", project.name);
        formData.append("adress", project.adress);
        formData.append("searchKey", project.searchKey);
        formData.append("dataSheetId", project.dataSheetId)

        const result = await axios.post("/projects?connectionId=" + connectionId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async uploadNewProjectVersion(projectId, model, connectionId) {
        const formData = new FormData();

        formData.append("objectKey", model.objectKey);

        const result = await axios.patch("/projects/" + projectId + "?connectionId=" + connectionId, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async editProject(projectId, data) {
        const formData = new FormData();

        formData.append("name", data.name);
        formData.append("adress", data.adress);
        formData.append("searchKey", data.searchKey);
        formData.append("dataSheetId", data.dataSheetId);
        formData.append("autoUpdate", data.autoUpdate);

        const result = await axios.patch("/projects/" + projectId + "/data", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    async deleteProjects(ids) {
        const result = await axios({
            url: "/projects",
            method: "DELETE",
            data: ids
        });

        return result.data;
    }

    async login(userData) {
        const formData = new FormData();

        formData.append('name', userData.name);
        formData.append('password', userData.password);

        const result = await axios.post('/login', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return result.data;
    }

    getAccessToken() { return this._accessToken; }

    setAccessToken(value) {
        this._accessToken = value;
        axios.defaults.headers.common[AuthorizationHeader] = `Bearer ${value}`;
    }

    forgetAccessToken() {
        delete axios.defaults.headers.common[AuthorizationHeader];
        delete this._accessToken;
    }

    hasAccessToken() { return !!this._accessToken; }
}

export default new Repository();