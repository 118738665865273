import { combineReducers } from 'redux';
import projectsListReducer, * as projects from './projectsListReducer'
import uiFlagsReducer, * as uiFlags from './uiFlagsReducer';
import constructionsReducer, * as constructionsList from "./constructionsReducer";
import profileReducer, * as profile from './profileReducer'
import loginReducer, * as uilogin from './loginReducer'

export const mainReducer = combineReducers({
    projectsList: projectsListReducer,
    uiFlags: uiFlagsReducer,
    constructions: constructionsReducer,
    profile: profileReducer,
    login: loginReducer
});

export const isMobile = function (state) {
    return uiFlags.isMobile(state.uiFlags);
}

export const isVisualizationControlVisibleForMobileDevice = function (state) {
    return uiFlags.isVisualizationControlVisibleForMobileDevice(state.uiFlags)
}

// PROFILE
export const isLoggedIn = function (state) {
    return profile.isLoggedIn(state.profile);
}

export const getProfile = function (state) {
    return state.profile;
};


// PROJECTS
export const checkedProjects = function (state) {
    return uiFlags.checkedProjects(state.uiFlags);
}

export const getActiveProject = function (state) {
    return projects.getActiveProject(state.projectsList);
}

export const getProject = function(id, state) {
    return projects.getProject(id, state.projectsList);
}

export const areProjectsLoaded = function (state) {
    return uiFlags.areProjectsLoaded(state.uiFlags);
}


//TABS
export const activeTabIndex = function (state) {
    return uiFlags.activeTabIndex(state.uiFlags);
};


//CONSTRUCTIONS
export const isConstructionLoading = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return true;

    return constructionsList.isConstructionLoading(activeProject.dataSheetId, state.constructions);
}

export const getConstructionUpdatedAt = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return null;

    return constructionsList.getConstructionUpdatedAt(activeProject.dataSheetId, state.constructions);
}

export const getConstructionItems = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return [];

    return constructionsList.getConstructionItems(activeProject.dataSheetId, state.constructions)
}

export const getConstructionStages = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return [];

    return constructionsList.getConstructionStages(activeProject.dataSheetId, state.constructions);
}

export const getConstructionDates = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return {
            minDate: new Date(),
            maxDate: new Date(),
            currentMinDate: new Date(),
            currentMaxDate: new Date()
        }

    return constructionsList.getConstructionDates(activeProject.dataSheetId, state.constructions);
}

export const areAllStagesChecked = function (state) {
    const activeProject = getActiveProject(state);

    if (!activeProject)
        return true;

    return constructionsList.areAllStagesChecked(activeProject.dataSheetId, state.constructions);
}

// LOGIN
export const getLoginResults = function(state) {
    return uilogin.getLoginResults(state.login);
}

export const canSignIn = function(state) {
    return uilogin.canSignIn(state.login);
}

export const getUserName = function(state) {
    return uilogin.getUserName(state.login);
}

export const getPassword = function(state) {
    return uilogin.getPassword(state.login);
}

export const getUserData = function(state) {
    return {
        name: getUserName(state),
        password: getPassword(state)
    }
}

// NEW PROJECT
export const isNewProjectDialogVisisble = function (state) {
    return uiFlags.isNewProjectDialogVisisble(state.uiFlags);
}

export const isNewVersionDialogVisible = function (state) {
    return uiFlags.isNewVersionDialogVisible(state.uiFlags);
}

export const isProjectDataEditorDialogVisible = function (state) {
    return uiFlags.isProjectDataEditorDialogVisible(state.uiFlags)
}

export const getNewModelSource = function(state) {
    return uiFlags.getNewModelSource(state.uiFlags);
}

export const getCreateNewProjectError = function (state) {
    return uiFlags.getCreateNewProjectError(state.uiFlags);
}

export const getCurrentProjecEditionState = function (state) {
    return uiFlags.getCurrentProjecEditionState(state.uiFlags);
}

export const isProjectsDeleteDialogVisible = function (state) {
    return uiFlags.isProjectsDeleteDialogVisible(state.uiFlags);
}

// MODEL UPLOAD AND PROCESSING
export const isProcessingPaneVisible = function (state) {
    return uiFlags.isProcessingPaneVisible(state.uiFlags);
}

export const getProcessingMessage = function (state) {
    return uiFlags.getProcessingMessage(state.uiFlags);
}