import repo from '../Repository';
import { addError, addLog } from './notificationActions';
import { setProjectProcessingVisibility, reportProjectProcessingProgress } from "./projectListActions";
import { checkedProjects, getProject } from "../reducers/mainReducer";

export const actionTypes = {
    PROJECT_CONSTRUCTION_LIST_UPDATED: 'PROJECT_CONSTRUCTION_LIST_UPDATED',
    PROJECT_CONSTRUCTION_LIST_FETCH_STARTED: 'PROJECT_CONSTRUCTION_LIST_FETCH_STARTED',
    PROJECT_STAGES_CHECKED_CHANGED: 'PROJECT_STAGES_CHECKED_CHANGED',
    PROJECT_STAGES_ALL_CHECKED_CHANGED: 'PROJECT_STAGES_ALL_CHECKED_CHANGED',
    PROJECT_MIN_DATE_CHANGED: 'PROJECT_MIN_DATE_CHANGED',
    PROJECT_MAX_DATE_CHANGED: 'PROJECT_MAX_DATE_CHANGED',
    PROJECT_RESET_DATES: 'PROJECT_RESET_DATES',
    PROJECT_SET_CURRENT_WEEK: 'PROJECT_SET_CURRENT_WEEK',
    PROJECT_SET_TODAY: 'PROJECT_SET_TODAY'
}

export default actionTypes;

export const startFetchConstructions = (sheetId) => {
    return {
        type: actionTypes.PROJECT_CONSTRUCTION_LIST_FETCH_STARTED,
        sheetId
    }
}

export const updateCostructionsList = (sheetId, constructions) => {
    return {
        type: actionTypes.PROJECT_CONSTRUCTION_LIST_UPDATED,
        constructions,
        sheetId
    };
}

export const setStageChecked = (sheetId, stageName, isChecked) => {
    return {
        type: actionTypes.PROJECT_STAGES_CHECKED_CHANGED,
        sheetId,
        stageName,
        isChecked
    }
}

export const setAllStagesChecked = (sheetId, isChecked) => {
    return {
        type: actionTypes.PROJECT_STAGES_ALL_CHECKED_CHANGED,
        sheetId,
        isChecked
    }
}

export const setConstructionsMinDate = (sheetId, minDate) => {
    return {
        type: actionTypes.PROJECT_MIN_DATE_CHANGED,
        sheetId,
        minDate
    }
}

export const setConstructionsMaxDate = (sheetId, maxDate) => {
    return {
        type: actionTypes.PROJECT_MAX_DATE_CHANGED,
        sheetId,
        maxDate
    }
}

export const resetConstructionsDates = (sheetId) => {
    return {
        type: actionTypes.PROJECT_RESET_DATES,
        sheetId
    }
}

export const setCurrentWeekConstructionDates = (sheetId) => {
    return {
        type: actionTypes.PROJECT_SET_CURRENT_WEEK,
        sheetId
    }
}

export const setToday = (sheetId) => {
    return {
        type: actionTypes.PROJECT_SET_TODAY,
        sheetId
    }
}

export const fetchConstructions = (sheetId) => async (dispatch, getState) => { 
    const state = getState();

    if (state.constructions.constructions[sheetId])
        return;

    dispatch(addLog("Load constructions invoked"))

    dispatch(startFetchConstructions(sheetId));

    try {
        const data = await repo.loadConstructions(sheetId);
        dispatch(addLog('Load constructions received'));
        dispatch(updateCostructionsList(sheetId, data));
    } catch (e) {
        dispatch(addError('Failed to get constructions. (' + e + ')'));
        dispatch(fetchConstructions(sheetId));
    }
}

export const updateConstructionsFromGoogleSheets = () => async(dispatch, getState) => {
    const state = getState();

    const projects = checkedProjects(state).map(x => getProject(x, state));
    
    dispatch(setProjectProcessingVisibility(true));
    dispatch(reportProjectProcessingProgress("Обновление данных..."))

    for (let i = 0; i < projects.length; ++i) {
        const project = projects[i];
        
        await repo.updateConstructions(project.id)

        const updatedConstruction = await repo.loadConstructions(project.dataSheetId);

        dispatch(updateCostructionsList(project.dataSheetId, updatedConstruction));
    }

    dispatch(setProjectProcessingVisibility(false));
}