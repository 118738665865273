import * as signalR from '@aspnet/signalr';

export class JobProgress {
    async startConnection() {
        const connection = new signalR.HubConnectionBuilder()
            .withUrl('/signalr/connection')
            .configureLogging(signalR.LogLevel.Warning)
            .build();

        await connection.start();
        return connection;
    }

    async subscribeToProcessModelsEvents(onProgress, onCompleted, onFailed) {
        const connection = await this.startConnection();

        connection.on("stopListening", () => {
            connection.stop();
        });

        connection.on("onProgress", (message) => {
            onProgress(message);
        })

        connection.on("onCompleted", () => {
            onCompleted();
            connection.stop();
        });

        connection.on("onFailed", (message) => {
            onFailed(message);
            connection.stop();
        });

        return await connection.invoke("getCurrentConnectionId");
    }
}

export default new JobProgress();