import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProjectAccountSwitcher from '@hig/project-account-switcher';
import { updateActiveProject } from '../actions/projectListActions';
import { updateActiveTabIndex } from '../actions/uiFlagsActions';
import { getActiveProject, activeTabIndex } from "../reducers/mainReducer";
import "./projectSwticher.css"

export class ProjectSwticher extends Component {
    constructor(props) {
        super(props);

        this.onProjectChange = this.onProjectChange.bind(this)
    }

    onProjectChange(data) {
        const id = data.project.id;
        this.props.updateActiveProject(id);

        if (this.props.needToSwitchTab)
            this.props.updateActiveTabIndex(1);
    }
    
    render() {
        return (<div className="projectsSwitcher"><ProjectAccountSwitcher
            defaultProject={this.props.projectsList.activeProjectId}
            activeProject={null}
            activeLabel={this.props.activeProject?.name}
            projects={this.props.projectsList.projects}
            projectTitle="Проекты"
            onChange={this.onProjectChange}
        /></div>);
    }
}

export default connect(function (store) {
    return {
        projectsList: store.projectsList,
        activeProject: getActiveProject(store),
        needToSwitchTab: activeTabIndex(store) === 0
    };
}, { updateActiveProject, updateActiveTabIndex  })(ProjectSwticher);