import React, { Component } from 'react';
import { connect } from 'react-redux';
import Surface from '@hig/surface';
import Toolbar from './components/toolbar';
import ProjectSwticher from "./components/projectSwticher";
import TabsContainer from "./components/tabsContainer";
import LoginForm from "./components/loginForm";
import VisualizationControl from "./components/visualizationControl";
import ForgeView from "./components/forgeView";
import { detectToken } from "./actions/profileActions";
import { fetchProjects } from "./actions/projectListActions";
import { isLoggedIn, isMobile } from "./reducers/mainReducer";
import './App.css';

export class App extends Component {
  componentDidMount() {
    this.props.detectToken()
  }
  render() {
    return (
      <Surface className="fullheight" id="main" level={200}>
        <Toolbar>
          {this.props.isLoggedIn === "logged" && <ProjectSwticher />}
        </Toolbar>
        { this.props.isLoggedIn === "logged" && <TabsContainer />}
        { this.props.isLoggedIn === "not-logged" && <LoginForm />}
        { this.props.isLoggedIn === "anonymous-gallery" &&
          <div className="fullheight">
            <div className="anonymous-pane fullheight">
              {! this.props.isMobile && <VisualizationControl />}
              <ForgeView />
            </div>
          </div>}
      </Surface>
    );
  }
}

export default connect(function (store) {
  return {
    isLoggedIn: isLoggedIn(store),
    isMobile : isMobile(store)
  }
}, { detectToken, fetchProjects })(App);