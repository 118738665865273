import repo from '../Repository';
import {addError, addLog} from './notificationActions';
import { fetchConstructions } from "./constructionActions";
import { showCreateNewProjectDialog, showCreateNewProjectError, showUploadNewVersionDialog, showEditProjectDialog, showDeleteProjectsDialog } from "./uiFlagsActions";
import { getNewModelSource, checkedProjects } from "../reducers/mainReducer";
import JobProgress from "../jobProgress";

export const actionTypes = {
    PROJECT_LIST_UPDATED: 'PROJECT_LIST_UPDATED',
    ACTIVE_PROJECT_UPDATED: 'ACTIVE_PROJECT_UPDATED',
    CURRENT_PROJECT_CLEARED: 'CURRENT_PROJECT_CLEARED',
    PROCESS_PROJECT: 'PROCESS_PROJECT',
    PROCESS_PROJECT_REPORT_PROGRESS: 'PROCESS_PROJECT_REPORT_PROGRESS'
}

export default actionTypes;

export const updateProjectList = projectList => {
    return {
        type: actionTypes.PROJECT_LIST_UPDATED,
        projectList: projectList
    };
};

export const setActiveProject = activeProjectId => {
    return {
        type: actionTypes.ACTIVE_PROJECT_UPDATED,
        activeProjectId: activeProjectId
    };
}

export const updateActiveProject = activeProjectId => async (dispatch, getState) => {
    dispatch(setActiveProject(activeProjectId));

    var project = getState().projectsList.projects.find(x => x.id === activeProjectId)
    
    dispatch(fetchConstructions(project.dataSheetId))
};

export const clearActiveProject = () => {
    return {
        type: actionTypes.CURRENT_PROJECT_CLEARED
    };
}

export const fetchProjects = () => async (dispatch, getState) => { 
    dispatch(addLog("Load projects invoked"))

    try {
        const data = await repo.loadProjects();
        dispatch(addLog('Load projects received'));
        dispatch(updateProjectList(data));

        if (data.length > 0)
            dispatch(updateActiveProject(data[0].id));
    } catch (e) {
        dispatch(addError('Failed to get Projects list. (' + e + ')'));
    }
}

export const fetchCurrentProject = (locationPath) => async (dispatch, getState) => {
    dispatch(addLog("Load " + locationPath + " invoked"));

    try {
        const data = await repo.loadProject(locationPath);

        dispatch(addLog('Load projects received'));
        dispatch(updateProjectList(data));

        if (data.length > 0)
            dispatch(updateActiveProject(data[0].id));
    } catch (e) {
        dispatch(addError('Failed to get Projects list. (' + e + ')'));
    }
}

export const createNewProject = (projectData) => async (dispatch, getState) => {
    dispatch(addLog("Create new project invoked"));

    dispatch(setProjectProcessingVisibility(true))
    dispatch(reportProjectProcessingProgress("Загрузка модели..."))

    const modelSource = getNewModelSource(getState());

    let uploadStorage;

    try {
        uploadStorage = await repo.uploadModel(modelSource);
    } catch (e) {
        dispatch(setProjectProcessingVisibility(false));
        const message = e.response?.data?.message || "Ошибка сервера. Повторите операцию позже";
        dispatch(showCreateNewProjectError(message));

        return;
    }

    const onProgress = function (message) {
        dispatch(reportProjectProcessingProgress(message));
    }

    const onCompleted = function () {
        dispatch(setProjectProcessingVisibility(false));
        dispatch(showCreateNewProjectDialog(false));
        dispatch(fetchProjects());
    }

    const onFailed = function (message) {
        dispatch(setProjectProcessingVisibility(false));
        dispatch(showCreateNewProjectError(message));
    }

    const connectionId = await JobProgress.subscribeToProcessModelsEvents(onProgress, onCompleted, onFailed);

    let projectCreationResponse;

    try {
        projectCreationResponse = await repo.createNewProject(projectData, uploadStorage, connectionId);
    } catch (e) {
        if (e.response.data?.message)
            projectCreationResponse = e.response.data;
        else {
            dispatch(setProjectProcessingVisibility(false));
            dispatch(showCreateNewProjectError("Ошибка сервера. Повторите операцию позже"));

            return;
        }
    }

    if (!projectCreationResponse.isSuccess) {
        dispatch(setProjectProcessingVisibility(false));

        dispatch(showCreateNewProjectError(projectCreationResponse.message));

        return;
    }
}

export const uploadNewProjectVersion = () => async(dispatch, getState) => {
    const state = getState();
    const modelSource = getNewModelSource(state);

    const currentCheckedProjects = checkedProjects(state);

    if (!currentCheckedProjects || currentCheckedProjects.length === 0)
        return;

    const projectId = currentCheckedProjects[0];

    dispatch(addLog("Create new project invoked"));

    dispatch(setProjectProcessingVisibility(true));
    dispatch(reportProjectProcessingProgress("Загрузка модели..."));

    let uploadStorage;

    try {
        uploadStorage = await repo.uploadModel(modelSource);
    } catch (e) {
        dispatch(setProjectProcessingVisibility(false));
        const message = e.response?.data?.message || "Ошибка сервера. Повторите операцию позже";
        dispatch(showCreateNewProjectError(message));

        return;
    }

    const onProgress = function (message) {
        dispatch(reportProjectProcessingProgress(message));
    }

    const onCompleted = function () {
        dispatch(setProjectProcessingVisibility(false));
        dispatch(showUploadNewVersionDialog(false));
        dispatch(fetchProjects());
    }

    const onFailed = function (message) {
        dispatch(setProjectProcessingVisibility(false));
        dispatch(showCreateNewProjectError(message));
    }

    const connectionId = await JobProgress.subscribeToProcessModelsEvents(onProgress, onCompleted, onFailed);

    let projectCreationResponse;

    try {
        projectCreationResponse = await repo.uploadNewProjectVersion(projectId, uploadStorage, connectionId);
    } catch (e) {
        if (e.response.data?.message)
            projectCreationResponse = e.response.data;
        else {
            dispatch(setProjectProcessingVisibility(false));
            dispatch(showCreateNewProjectError("Ошибка сервера. Повторите операцию позже"));

            return;
        }
    }

    if (!projectCreationResponse.isSuccess) {
        dispatch(setProjectProcessingVisibility(false));

        dispatch(showCreateNewProjectError(projectCreationResponse.message));

        return;
    }
}

export const editProject = (projectId, newData) => async(dispatch, getState) => {
    dispatch(addLog("edit project"));

    dispatch(setProjectProcessingVisibility(true))
    dispatch(reportProjectProcessingProgress("Обновление проекта..."))

    let projectCreationResponse;

    try {
        projectCreationResponse = await repo.editProject(projectId, newData);
    } catch (e) {
        if (e.response.data?.message)
            projectCreationResponse = e.response.data;
        else {
            dispatch(setProjectProcessingVisibility(false));
            dispatch(showCreateNewProjectError("Ошибка сервера. Повторите операцию позже"));

            return;
        }
    }

    dispatch(setProjectProcessingVisibility(false));

    if (!projectCreationResponse.isSuccess) {
        dispatch(showCreateNewProjectError(projectCreationResponse.message));
    } else {
        dispatch(showEditProjectDialog(false));
        dispatch(fetchProjects());
    }
}

export const deleteProjects = () => async(dispatch, getState) => {
    const state = getState();

    const ids = checkedProjects(state);

    dispatch(addLog("delete projects"));

    let response;

    try {
        response = await repo.deleteProjects(ids);
    } catch (e) {
        if (e.response.data?.message)
            response = e.response.data;
        else {
            alert("Ошибка сервера. Повторите операцию позже");

            return;
        }
    }

    dispatch(showDeleteProjectsDialog(false));
    
    if (!response.isSuccess) {
        alert(response.message);
    } else {
        dispatch(fetchProjects());
    }
}

export const setProjectProcessingVisibility = (visible) => {
    return {
        type: actionTypes.PROCESS_PROJECT,
        visible
    }
}

export const reportProjectProcessingProgress = (message) => {
    return {
        type: actionTypes.PROCESS_PROJECT_REPORT_PROGRESS,
        message
    }
}