import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Column } from 'react-base-table';
import BaseTable, { AutoResizer, FrozenDirection } from 'react-base-table';
import ProjectsTableHeader from "./projectsTableHeader";
import ProjectsTableRow from "./projectsTableRow";
import styled from 'styled-components';
import { setProjectChecked, setCheckedProjects, clearCheckedProjects } from "../actions/uiFlagsActions";

const CHECK_COLUMN = 'check_column';
const Icon = ({ iconname }) => (
    <div>
        <img src={iconname} alt='' width='24px' height='24px' />
    </div>
);

const iconRenderer = ({ cellData: iconname }) => <Icon iconname={iconname} />;
const nameDetailsRenderer = ({cellData: nameDetails}) => <div style={{ whiteSpace: 'pre-wrap' }}>{nameDetails.name} <a href={"/" + nameDetails.searchKey} target="_blank" rel="noreferrer">Открыть</a></div>

const cellBackgroundColor = 'white';
const cellHoverColor = '#f3f3f3';

const Cell = styled.div`
  background-color: ${cellBackgroundColor};
`;

const Row = styled.div`
  [id=checkbox_hover_visible] {
    display:none;
  }

  &:hover {
    [id=checkbox_hover_visible] {
      display:block;
    }
  }
`;

const HeaderRow = styled.div`
  display: flex;
  background-color: ${cellBackgroundColor};
  width: inherit;
  height: calc(100% - 1px); // this keeps header underlined

  [id=checkbox_hover_visible] {
    display:none;
  }

  &:hover {
    background-color: ${cellHoverColor};

    [id=checkbox_hover_visible] {
      display:block;
    }
  }  
`;


export class ProjectsTable extends Component {
    constructor(props) {
        super(props);

        this.projectsListColumns = [
            {
                width: 78,
                flexShrink: 0,
                locked: true,
                resizable: false,
                frozen: FrozenDirection.RIGHT,
                align: Column.Alignment.RIGHT,
                position: 1 - Number.MAX_VALUE,
                headerRenderer: () => (
                    <ProjectsTableHeader
                        onChange={(clearAll) => { this.onSelectAllChange(clearAll); }}
                        selectable={this.props.selectable}
                    />
                ),
                cellRenderer: ({ rowData }) => (
                    <ProjectsTableRow
                        rowData={rowData}
                        onChange={(checked, rowData) => { this.onSelectChange(checked, rowData); }}
                        selectable={this.props.selectable}
                    />
                ),
                key: CHECK_COLUMN
            },
            {
                key: 'icon',
                title: '',
                dataKey: 'image',
                cellRenderer: iconRenderer,
                align: Column.Alignment.RIGHT,
                width: 32
            },
            {
                key: 'label',
                title: 'Проект',
                dataKey: 'nameDetails',
                align: Column.Alignment.LEFT,
                width: 300,
                cellRenderer: nameDetailsRenderer
            },
            {
                key: 'details',
                title: 'Адрес',
                dataKey: 'adress',
                align: Column.Alignment.LEFT,
                width: 900
            }
        ]
    }

    onSelectAllChange(clearAll){
        if (clearAll) {
            this.props.clearCheckedProjects();
        } else {
            const projects = this.props.projectsList.projects ? this.props.projectsList.projects.map(station => station.id) : [];
            this.props.setCheckedProjects(projects);
        }
    }

    onSelectChange(checked, rowData) {
        this.props.setProjectChecked(rowData.id, checked)
    }


    render() {
        const data = this.props.projectsList.projects.map(x => {
            return { ...x, nameDetails: {
                name: x.name,
                searchKey: x.searchKey
            }}
        });

        return (
            <AutoResizer>
                {({ width, height }) => {
                    return <BaseTable
                        width={width}
                        height={height}
                        columns={this.projectsListColumns}
                        data={data}
                        rowHeight={50}
                        rowEventHandlers={{
                            onClick: ({ rowData }) => {
                                this.props.onProjectClick(rowData.id);
                            }
                        }}
                        cellProps={({ columnIndex }) =>
                            columnIndex === 0 && {
                                tagName: Cell,
                                onClick: e => {
                                    // stop click on the first cell (checkbox) and do not select project
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                        rowProps={{
                            tagName: Row // styled div to show/hide row checkbox when hover
                        }}
                        headerRenderer={({ cells }) => {
                            // prepare the first checkbox cell and override
                            // background color to be consistent with row checkbox cells
                            const headerCheckboxCell = cells[0];
                            const updatedCell = React.cloneElement(headerCheckboxCell, {
                                style: { ...headerCheckboxCell.props.style, backgroundColor: cellBackgroundColor }
                            });

                            cells.shift(); // remove original item

                            // HeaderRow - styled row with hovering enabled - render checkbox cell + rest of columns
                            return (<HeaderRow id="checkbox_hover_visible">{updatedCell}{cells}</HeaderRow>);
                        }}
                    />;
                }}
            </AutoResizer>
        )
    }
}

export default connect(function (store) {
    return {
        projectsList: store.projectsList
    };
}, { setProjectChecked, setCheckedProjects, clearCheckedProjects  })(ProjectsTable);