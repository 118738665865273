import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import ProgressBar from '@hig/progress-bar';
import { isProcessingPaneVisible, getProcessingMessage } from "../reducers/mainReducer";
import merge from "lodash.merge";
import "./projectProcessingPane.css";

export class ProjectProcessingPane extends Component {
    render() {
        const modalStyles = styles =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "200px"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        return (<React.Fragment>
            <div className="processing-pane">
                <Modal
                    open={this.props.isVisible} title="Обработка..." stylesheet={modalStyles}>
                    <div className="processingProgressPane">
                        <div className="processingProgressContent">
                            <div className="processingProgressImage" style={{ backgroundImage: "url('/Assembly_icon.svg')" }} />
                            <div className="processingProgressAction" fontWeight="bold">
                                <ProgressBar className="processingProgressBar" />
                            </div>
                        </div>
                        <div className="processingProgressTimePrompt">Обработка модели. Это действие может занять несколько минут</div>
                        {this.props.message && <div className="processingProgressStatusPane"><strong>Статус</strong>: {this.props.message}</div>}
                    </div>
                </Modal>
            </div>
        </React.Fragment>)
    }
}

export default connect(function (store) {
    return {
        isVisible: isProcessingPaneVisible(store),
        message: getProcessingMessage(store)
    }
}, {})(ProjectProcessingPane)