import actions from '../actions/profileActions';

export const initialState = {
    isLoggedIn: "unknown",
    name: "Anonymous",
    avatarUrl: "profile.svg"
};

export const isLoggedIn = function(state){
    return state.isLoggedIn;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch(action.type) {
        case actions.PROFILE_LOADED: {
            return { isLoggedIn: action.isLoggedIn, name: action.profile.name, avatarUrl: action.profile.avatarUrl};
        }
        default:
            return state;
    }
}