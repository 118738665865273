import projectListActionTypes from '../actions/projectListActions';

export const initialState = {
    activeProjectId: null,
    projects: []
};

export const getActiveProject = function(state) {
    if (state.activeProjectId === null) return null;

    return getProject(state.activeProjectId, state);
}

export const getProject = function(id, state) {
    return state.projects.find(x => x.id === id);
}

function ensureActiveProjectId(projects, currId) {
    const activeProject = projects.find(({ id }) => id === currId);
    const prj = activeProject ? activeProject : (projects.length ? projects[0] : null);
    const prjId = prj ? prj.id : null;
    return prjId;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default function(state = initialState, action) {
    switch (action.type){
        case projectListActionTypes.PROJECT_LIST_UPDATED: {
            const prjId = ensureActiveProjectId(action.projectList, state.activeProjectId);
            return {...state, activeProjectId: prjId, projects: action.projectList }
        }

        case projectListActionTypes.ACTIVE_PROJECT_UPDATED:
            return { ...state, activeProjectId: action.activeProjectId }
        
        case projectListActionTypes.CURRENT_PROJECT_CLEARED:
            return { ...state, activeProjectId: null }

        default:
            return state;
    }
}