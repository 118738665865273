import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import Label from '@hig/label';
import Input from '@hig/input';
import Button from '@hig/button';
import Typography from "@hig/typography";
import IconButton from '@hig/icon-button';
import Spacer from '@hig/spacer';
import { Folder24 } from '@hig/icons';
import merge from "lodash.merge";


import { isNewProjectDialogVisisble, getNewModelSource, getCreateNewProjectError } from "../reducers/mainReducer";
import { showCreateNewProjectDialog, selectModelSourceFile, showCreateNewProjectError } from "../actions/uiFlagsActions";
import { createNewProject } from "../actions/projectListActions";

import './projectForm.css';

export class CreateNewProjectForm extends Component {
    constructor(props) {
        super(props);
        this.onPackageFileChange = this.onPackageFileChange.bind(this);

        this.newProject = {
            name: "",
            searchKey: "",
            dataSheetId: "",
            adress: ""
        }
    }

    onPackageFileChange(data) {
        if (data.target.files.length > 0) {
            this.props.selectModelSourceFile(data.target.files[0])
        }
    }

    onProjectKeyChanged(data) {
        this.newProject.searchKey = data;

        this.props.showCreateNewProjectError(null);
    }

    render() {
        const modalStyles = styles =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "400px",
                        height: "600px"
                    },
                    bodyContent: {
                        overflow: "hidden" // no scrollbar
                    }
                }
            });

        const hasUpload = this.props.projectSourceData.file?.name.endsWith('.dwg') === true;
        return (<React.Fragment>
            <Modal
                open={this.props.isVisible}
                title="Новый проект"
                onCloseClick={() => this.props.showCreateNewProjectDialog(false)}
                stylesheet={modalStyles}>
                <div id="project-data">
                    <div className="fileBrowseContainer">
                        <div className="stretch">
                            <Label
                                variant="top"
                                disabled={false} >
                                DWG файл модели:
                                </Label>
                            <Input
                                variant="box"
                                value={this.props.projectSourceData.file?.name || ''}
                                disabled={true}
                            />
                        </div>
                        <div className="browseButton">
                            <label htmlFor="modelFileInput">
                                <IconButton
                                    icon={<Folder24 />}
                                    title="Выберите DWG-файл модели"
                                    onClick={() => { document.getElementById("modelFileInput").click(); }}
                                />
                            </label>
                            <input id="modelFileInput"
                                type="file"
                                accept=".dwg"
                                onChange={(e) => {
                                    this.onPackageFileChange(e);
                                }}
                            />
                        </div>
                    </div>
                    <div className="stretch">
                        <Label variant="top">Ключ (будет в строке запроса браузера):</Label>
                        <Input variant="box" onChange={data => this.onProjectKeyChanged(data.target.value)}/>
                    </div>

                    <div className="stretch">
                        <Label variant="top">ID таблицы Google:</Label>
                        <Input variant="box" onChange={data => this.newProject.dataSheetId = data.target.value}/>
                    </div>

                    <div className="stretch">
                        <Label variant="top">Наименование:</Label>
                        <Input variant="box" onChange={data => this.newProject.name = data.target.value} />
                    </div>

                    <div className="stretch">
                        <Label variant="top">Адрес:</Label>
                        <Input variant="box" onChange={data => this.newProject.adress = data.target.value} tagName="textarea" />
                    </div>

                    {this.props.errorMessage !== null && <Typography children={this.props.errorMessage} className="creation-failed-message"/>}

                    <Spacer spacing="l" />
                    <div className="buttonsContainer">
                        <Button
                            size="standard"
                            title="Создать"
                            type="primary"
                            onClick={() => this.props.createNewProject(this.newProject)} 
                            disabled={!hasUpload}/>
                        <div style={{ width: '14px' }} />
                        <Button
                            id="cancel_button"
                            size="standard"
                            title="Отмена"
                            type="secondary"
                            onClick={() => { this.props.showCreateNewProjectDialog(false); }} />
                    </div>
                </div>
            </Modal>
        </React.Fragment>)
    }
}

export default connect(function (store) {
    return {
        isVisible: isNewProjectDialogVisisble(store),
        projectSourceData: getNewModelSource(store),
        errorMessage: getCreateNewProjectError(store)
    }
}, { showCreateNewProjectDialog, selectModelSourceFile, showCreateNewProjectError, createNewProject })(CreateNewProjectForm)