import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import Typography from "@hig/typography";
import Spacer from '@hig/spacer';
import Label from '@hig/label';
import Input from '@hig/input';
import Button from "@hig/button";
import { editLogin, editPassword, tryLogin } from '../actions/loginActions'
import { canSignIn, getUserName, getPassword, getLoginResults } from '../reducers/mainReducer';
import './loginForm.css';

export class LoginForm extends Component {
    render() {
        return (<div className="loginMainContainer">
            <React.Fragment><Modal open title="Login" className="loginform">
            <Typography>Введите учетные данные:</Typography>
            <Spacer />
            <Label>Login:</Label>
            <Input variant="box" value={this.props.userName} onChange={data => this.props.editLogin(data.target.value)}></Input>
            <Label>Пароль:</Label>
            <Input variant="box" className="passwordbox" value={this.props.password} onChange={data => this.props.editPassword(data.target.value)}></Input>
            {!this.props.loginResults.isSuccess && <Typography children={this.props.loginResults.message} className="invalid-login"/>}
            <Spacer  spacing="l"/>
            <div className="buttonsContainer">
                <Button size="standard"
                        title="Войти"
                        type="primary"
                        disabled={!this.props.canSignIn} 
                        onClick={() => this.props.tryLogin()}
                        />
            </div>
        </Modal></React.Fragment>
        </div>)
    }
}

export default connect(function (store) {
    return {
        canSignIn: canSignIn(store),
        userName: getUserName(store),
        password: getPassword(store),
        loginResults: getLoginResults(store)
    }
}, { editLogin, editPassword, tryLogin })(LoginForm);