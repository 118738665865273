import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { stopReportingRuntimeErrors } from "react-error-overlay";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';

import {mainReducer} from './reducers/mainReducer';

import "@hig/fonts/build/ArtifaktElement.css";

async function initServiceWorker() {
    try {
        const registration = await navigator.serviceWorker.register('/service-worker.js');
        console.log('Service worker registered', registration.scope);
    } catch (err) {
        console.error('Could not register service worker', err);
    }
}

initServiceWorker();

/* eslint-disable no-undef */

// Turn off exception overlay on develop environment because ForgeViewer is causing an issue while removing it from DOM by throwing unhandled exceptions (by showing and immediately hiding ForgeViewer)
// Even though these exception disturb our customers and workflows, since they are unhandled, react decides to show them in an overlay which is causing our PR check automations to fail
if (process.env.NODE_ENV !== 'production') {
    stopReportingRuntimeErrors(); // disables error overlays
}

const createStoreWithMiddleware = applyMiddleware(thunk)(createStore);
const store = createStoreWithMiddleware(mainReducer);

// based on https://github.com/reduxjs/redux/issues/303#issuecomment-125184409
function observeStore(store, select, onChange) {
    let currentState;

    function handleChange() {
        const nextState = select(store.getState());
        if (nextState !== currentState) {
            onChange(currentState, nextState);
            currentState = nextState;
        }
    }

    const unsubscribe = store.subscribe(handleChange);
    handleChange();
    return unsubscribe;
}

const logSelector = (state) => state.notifications;

/** Dump log messages into browser console */
function logToConsole(oldMessages, newMessages) { // TODO: not clear how to differentiate 'information' from 'error' messages

  const from = oldMessages ? oldMessages.length : 0;
    for (let i = from; i < newMessages.length; i++) {
        // eslint-disable-next-line no-console
        console.log(newMessages[i]);
    }
}

// listen for notifications changes and stream them into console
observeStore(store, logSelector, logToConsole);

const rootElement = document.getElementById('root');

ReactDOM.render(
<Provider store={store}>
  <App />
</Provider>, rootElement
);
