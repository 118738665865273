import React, { Component } from 'react';
import { connect } from 'react-redux';
import Typography from "@hig/typography";
import { getProfile, isLoggedIn, isMobile, isVisualizationControlVisibleForMobileDevice, isConstructionLoading } from '../reducers/mainReducer';
import UserDetails from './userDetails.js';
import { offsetPanelHorizontal, offsetContainerVertical } from "@hig/flyout"
import { logout } from "../actions/loginActions";
import { showVisualizationControlMobile } from "../actions/uiFlagsActions";
import TopNav, {
    Logo,
    LogoText,
    Interactions,
    NavAction,
    Separator
} from '@hig/top-nav';

import Loader from "./loader";
import styled from 'styled-components';
import { Profile24, List24 } from "@hig/icons";

const PlaceCenterContainer = styled.div`
    align-items: center;
    display: flex;
  `;

export class Toolbar extends Component {
    signout() {
        logout();
    }

    render() {
        const visualizationControlStyle = this.props.isConstructionLoading ? "mobile-visualization-control-inactive" : "mobile-visualization-control-active";
        const visualizationControlButtonStyle = this.props.isVisualizationControlShownInMobile ? "mobile-visualization-control-button-active" : "mobile-visualization-control-button-inactive";
        return (<TopNav
            logo={
                <Logo link="https://forge.autodesk.com" label="Construction Visualization 4D">
                    <PlaceCenterContainer>
                        <img src={"logo.svg"} alt="" />
                        <LogoText>
                            <Typography style={{ fontSize: '16px' }}><strong>Construction Visualization 4D</strong></Typography>
                            <Typography style={{ fontSize: '11px', marginLeft: '60px' }}>powered by AUTODESK<sup>®</sup> FORGE</Typography>
                        </LogoText>
                    </PlaceCenterContainer>
                </Logo>
            }
            rightActions={
                <React.Fragment>
                    <PlaceCenterContainer>
                        {this.props.children}
                    </PlaceCenterContainer>
                    <Interactions>
                        {this.props.isLoggedIn === "logged" &&
                            <Separator />}
                        {this.props.isLoggedIn === "logged" &&
                            <NavAction title="Профиль" icon={<Profile24 />} alterCoordinates={coordinates => offsetPanelHorizontal(offsetContainerVertical(coordinates, 10), 6)}>
                                <UserDetails profile={this.props.profile} logout={() => this.signout()} login={() => { }}></UserDetails>
                            </NavAction>}
                        {this.props.isMobile && this.props.isConstructionLoading &&
                            <div className="mini">
                                <Loader show={true}/>
                            </div>}
                        {this.props.isMobile &&
                            <div className={visualizationControlStyle + " mobile-visualization " + visualizationControlButtonStyle}>
                                <NavAction title="Стадии" icon={<List24 />}
                                    onClick={() => {
                                        if (!this.props.isConstructionLoading)
                                            this.props.showVisualizationControlMobile(!this.props.isVisualizationControlShownInMobile)
                                    }} />
                            </div>}
                    </Interactions>
                </React.Fragment>
            }
        />);
    }
}

export default connect(function (store) {
    return {
        profile: getProfile(store),
        isLoggedIn: isLoggedIn(store),
        isMobile: isMobile(store),
        isVisualizationControlShownInMobile: isVisualizationControlVisibleForMobileDevice(store),
        isConstructionLoading: isConstructionLoading(store)
    };
}, { showVisualizationControlMobile })(Toolbar);