export const actionTypes = {
    SET_PROJECT_CHECKED: 'SET_PROJECT_CHECKED',
    SET_CHECKED_PROJECTS: 'SET_CHECKED_PROJECTS',
    CLEAR_CHECKED_PROJECTS: 'CLEAR_CHECKED_PROJECTS',

    UPDATE_ACTIVE_TAB_INDEX: 'UPDATE_ACTIVE_TAB_INDEX',

    SHOW_CREATE_NEW_PROJECT: 'SHOW_CREATE_NEW_PROJECT',
    MODEL_SOURCE_FILE_SELECTED: 'MODEL_SOURCE_FILE_SELECTED',
    SHOW_CREATE_NEW_PROJECT_ERROR: 'SHOW_CREATE_NEW_PROJECT_ERROR',
    SHOW_UPLOAD_NEW_VERSION: 'SHOW_UPLOAD_NEW_VERSION',
    SHOW_EDIT_PROJECT_DATA: 'SHOW_EDIT_PROJECT_DATA',
    SET_PROJECT_EDITION_STATE: 'SET_PROJECT_EDITION_STATE',

    SHOW_DELETE_PROJECTS_DIALOG: 'SHOW_DELETE_PROJECTS_DIALOG',

    MOBILE_SHOW_VISUALIZATION_CONTROL: 'MOBILE_SHOW_VISUALIZATION_CONTROL'
}

export default actionTypes;

export const setProjectChecked = (projectId, checked) => {
    return {
        type: actionTypes.SET_PROJECT_CHECKED,
        projectId,
        checked
    }
}

export const setCheckedProjects = (projects) => {
    return {
        type: actionTypes.SET_CHECKED_PROJECTS,
        projects
    }
}

export const clearCheckedProjects = () => {
    return {
        type: actionTypes.CLEAR_CHECKED_PROJECTS
    }
}


export const updateActiveTabIndex = (index) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_TAB_INDEX,
        index
    };
};


export const showCreateNewProjectDialog = (visible) => {
    return {
        type: actionTypes.SHOW_CREATE_NEW_PROJECT,
        visible
    }
}

export const selectModelSourceFile = (file) => {
    return {
        type: actionTypes.MODEL_SOURCE_FILE_SELECTED,
        file
    }
}

export const showCreateNewProjectError = (message) => {
    return {
        type: actionTypes.SHOW_CREATE_NEW_PROJECT_ERROR,
        message
    }
}

export const showUploadNewVersionDialog = (visible) => {
    return {
        type: actionTypes.SHOW_UPLOAD_NEW_VERSION,
        visible
    }
}

export const showEditProjectDialog = (visible) => {
    return {
        type: actionTypes.SHOW_EDIT_PROJECT_DATA,
        visible
    }
}

export const setProjectEditionState = (state) => {
    return {
        type: actionTypes.SET_PROJECT_EDITION_STATE,
        state
    }
}

export const showDeleteProjectsDialog = (visible) => {
    return {
        type: actionTypes.SHOW_DELETE_PROJECTS_DIALOG,
        visible
    }
}

export const showVisualizationControlMobile = (visible) => {
    return {
        type: actionTypes.MOBILE_SHOW_VISUALIZATION_CONTROL,
        visible
    }
}