import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from '@hig/modal';
import Button from '@hig/button';
import Spacer from '@hig/spacer';
import merge from "lodash.merge";

import { isProjectsDeleteDialogVisible, checkedProjects, getProject } from "../reducers/mainReducer";
import { showDeleteProjectsDialog } from '../actions/uiFlagsActions';
import { deleteProjects } from "../actions/projectListActions";

import './deleteProjectsDialog.css';

export class DeleteProjectsDialog extends Component {
    render() {
        const modalStyles = styles =>
            merge(styles, {
                modal: {
                    window: { // by design
                        width: "370px"
                    }
                }
            });

        return (<React.Fragment>
            <Modal
                open={this.props.isVisible}
                title="Удаление проектов"
                onCloseClick={() => this.props.showDeleteProjectsDialog(false)}
                stylesheet={modalStyles}>
                <div id="deleteProjectsModal">
                    <div className="deleteProjectListContainer">
                        Вы уверены, что хотите удалить {this.props.projects.length === 1 ? 'проект' : 'следующие проекты'}?
                        <ul>
                            {this.props.projects.map(x => (<li key={x.id}>{x.name}</li>))}
                        </ul>
                    </div>
                    <Spacer spacing="l"/>
                    <div className="buttonsContainer">
                        <Button
                            id="delete_projects_ok_button"
                            size="standard"
                            title="Удалить"
                            type="primary"
                            onClick={() => this.props.deleteProjects()}
                        />
                        <div style={{width: '14px'}}/>
                        <Button
                            id="cancel_button"
                            size="standard"
                            title="Отмена"
                            type="secondary"
                            onClick={() => { this.props.showDeleteProjectsDialog(false); } }
                        />
                    </div>
                </div>
            </Modal>
        </React.Fragment>)
    }
}

export default connect(function (store) {
    return {
        isVisible: isProjectsDeleteDialogVisible(store),
        projects: checkedProjects(store).map(x => getProject(x, store)).filter(x => x)
    };
}, { showDeleteProjectsDialog, deleteProjects })(DeleteProjectsDialog);