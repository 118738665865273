import React, { Component } from 'react';
import './no-data.css'
import { Alert24 } from "@hig/icons";

export default class ContentNotReady extends Component {
    render() {
        return <div className="fullheight">
            <div className="no-data">
                <div className="title">
                    <Alert24 />&nbsp;{this.props.label}
                </div>
                <div className="image" style={{backgroundImage: "url('/no-data.svg')"}}></div>
            </div>
        </div>;
    }
}